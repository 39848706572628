import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import Video from './Video'


//import BackgroundImage from "gatsby-background-image"

const HeroComponent = ( props, { className, children }) => (
    <StaticQuery
        query={graphql`
            query {
                desktop: file(relativePath: { eq: "video_proxy.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `}
        render={data => {
            // Set ImageData.
            const imageData = data.desktop.childImageSharp.fluid
            return (
                <div className="hero">
                    <Video
                        videoSrcURL={ props.videoSrcURL }
                        videoTitle="Official Music Video on YouTube"
                        className="hero__video"
                    />
                    <div className="tagline-container">
                        <div className="container">
                            <div className="hero__content">
                                { props.children }
                            </div>
                        </div>
                    </div>
                    <BackgroundImage
                        Tag="section"
                        className="hero__proxy"
                        fluid={imageData}
                        backgroundColor={`#040e18`}
                        position={`static`}
                    >
                        {children}
                    </BackgroundImage>
                </div>
            )
        }}
    />
)

const Hero = styled(HeroComponent)`
  width: 100%;
  background-position: center center;
  background-size: cover;
  z-index: 1;
`

export default Hero