import React from "react"
import { Link } from "gatsby"
import { Helmet } from 'react-helmet'
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Hero from "../components/Hero"
import ImageText from "../components/ImageText"
import FeaturedContent from "../components/FeaturedContent"
import "../assets/sass/style.scss"

const IndexPage = props => (
  <Layout>
    <SEO title="Fitness Expert" description="Laura Williams revamped her life to become one of the UK’s leading fitness experts." />

    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Person",
            "email": "mailto:laura@laurawilliamsonline.co.uk",
            "image": "https://laurawilliamsonline.co.uk/static/e6b1d7fce9118fcbf5cb6dd1349ef37b/c252f/Laura_Williams_London%402x.jpg",
            "jobTitle": "Fitness Expert",
            "name": "Laura Williams ",
            "url": "https://laurawilliamsonline.co.uk"
          }
        `}
      </script>
    </Helmet>

    <Hero videoSrcURL="https://player.vimeo.com/video/508849934?background=1">
      <div className="tagline">
        <div>
          <span>Former couch potato,</span>
        </div>
        <div>
          <span>reluctant runner,</span>
        </div>
        <div>
          <span>tiny blonde dynamo…</span>
        </div>
      </div>
      <div className="cta">
        <p>Come and join the sculpt squad!</p>
        <div>
          <Link to="/classes" className="btn btn--primary btn--red">
            View Available Classes
          </Link>
          <Link to="/contact" className="btn btn--primary btn--red">
            Get In Touch
          </Link>
        </div>
      </div>
    </Hero>

    <FeaturedContent
      cssClass="featured-content bg-grey"
      heading="About Laura"
      imageSrc="about_laura@2x.jpg"
      imageAlt="About Laura"
      btnAction="/about"
      btnLabel="Find Out More"
    >
      <p>
        Laura Williams revamped her life to become one of the UK’s leading
        fitness experts.
      </p>
      <p>
        “As an ex-smoker who used to carry a couple of extra stone, I have a
        firm belief that if I can do it, anyone can.
      </p>
      <p>
        Today I enjoy teaching fitness to people who want to feel fitter and
        stronger without it feeling like too much of a chore.”
      </p>
    </FeaturedContent>

    <ImageText
      className="image-text bg-red"
      cssClass="image-text bg-red"
      heading="Classes"
      imageSrc="classes@2x.jpg"
      imageAlt="Classes photograph"
    >
      <p>
        Pilates Sculpt&reg; is Laura’s popular group
        exercise class. Designed to make you feel worked and welcome.
      </p>
      <Link to="/classes" className="btn btn--primary">
        Find Out More
      </Link>
    </ImageText>

    <ImageText
      className="image-text--reverse bg-grey"
      heading="Personal Training"
      imageSrc="personal_training@2x.jpg"
      imageAlt="Personal Training photograph"
    >
      <p>Laura offers tough, effective one-to-one sessions.</p>
      <Link to="/classes" className="btn btn--secondary">
        Find Out More
      </Link>
    </ImageText>

    <ImageText
      className="bg-red"
      heading="Community"
      imageSrc="homepage_community.jpg"
      imageAlt="Community photograph"
    >
      <p>
        Active within the Tower Hamlets community, Laura loves her role as the
        Area Lead for GoodGym, as well as working with multiple youth groups in
        the borough.
      </p>
      <Link to="/community" className="btn btn--primary">
        Find Out More
      </Link>
    </ImageText>

    <ImageText
      className="image-text--reverse bg-grey"
      heading="Journalism"
      imageSrc="journalism@2x.jpg"
      imageAlt="Journalism photograph"
    >
      <p>
        An experienced fitness journalist writing for HuffPost, Metro, WW
        magazine and NetDoctor, Laura uses her knowledge and experience to write
        accessible, relatable fitness content.
      </p>
      <Link to="/journalism" className="btn btn--secondary">
        Find Out More
      </Link>
    </ImageText>
  </Layout>
)

export default IndexPage
